import classNames from "classnames";
import Hamburger from "components/hamburger/hamburger";
import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import "./cookieNotice.scss";
interface Props {
  onClose: () => void;
}

const CookieNotice: React.FC<Props> = (props) => {
  const { language } = useI18next();

  return (
    <div className="cookieNotice">
      <div className="cookieNotice-bg"></div>
      <div className="cookieNotice-inner">
        <div className="cookieNotice-wrapper">
          <div className="cookieNotice-content">
            <div className="cookieNotice-text">
              {language === "de" ? (
                <>
                  <span className="cookieNotice-welcome">Wilkommen!</span> Diese
                  Website verwendet Cookies. Durch die Nutzung dieser Website
                  und ihrer Inhalte stimmen Sie der Verwendung von Cookies zu.{" "}
                  <a href="https://www.frankfurterbotschaft.de/impressum/">
                    Mehr erfahren.
                  </a>
                </>
              ) : (
                <>
                  <span className="cookieNotice-welcome">Welcome!</span> This
                  website uses cookies. By using this website and its content
                  you accept these cookies.{" "}
                  <a href="https://www.frankfurterbotschaft.de/en/imprint/">
                    Read more.
                  </a>
                </>
              )}
            </div>
            <button className="cookieNotice-button" onClick={props.onClose}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieNotice;
